import http from "../../http";

const merchant = {

    selectByPage(r) {
        return  http.spin.get("/gateway/api/merchant/merchant/v1/selectByPage",r )
    },
    selectMerchantLabel(r) {
        return  http.get("/gateway/api/merchant/merchant/v1/selectMerchantLabel",r )
    },
    selectById(r) {
        return http.spin.get("/gateway/api/merchant/merchant/v1/selectById",r )
    },
    selectTopList(r) {
        return  http.get("/gateway/api/merchant/merchant/v1/selectTopList",r )
    },
    selectList(r) {
        return  http.get("/gateway/api/merchant/merchant/v1/selectList",r )
    },
    addMerchant(r) {
        return  http.spin.payload("/gateway/api/merchant/merchant/v1/addMerchant",r )
    },
    updateMerchant(r) {
        return  http.spin.payload("/gateway/api/merchant/merchant/v1/updateMerchant",r )
    },
    delete(r) {
        return  http.confirm.tip.post("/gateway/api/merchant/merchant/v1/delete",r )
    },
    getAmountToDays(r) {
        return  http.get("/gateway/api/merchant/merchantStatApi/v1/getAmountToDays",r )
    },
    getIncomeByPeriod(r) {
        return  http.get("/gateway/api/merchant/merchantStatApi/v1/getIncomeByPeriod",r )
    },
    getMerchantIncomeByPeriod(r) {
        return  http.get("/gateway/api/merchant/merchantStatApi/v1/getMerchantIncomeByPeriod",r )
    },
}
export default merchant
